export function adjustmentOrder() {
	return {
		merchant: [],
		location: [],
		referenceId: '',
		items: [],
		status: '',
	}
}

export function currentAdjustmentItem() {
	return {
		product: '',
		variant: '',
		quantity: 0,
	}
}
