<template>
	<div class="grid lg:grid-cols-3 gap-5">
		<div class="lg:col-span-2 h-full w-full overflow-y-auto">
			<n-data-table title="調整列表" :columns="fields" :data="adjustmentOrders" actions="view,edit,search,refresh" @view="getAdjustmentOrder" @edit="archiveAdjustmentOrder">
				<template v-slot:merchant="{ row }"> {{ row.merchant.name['tc'] }}</template>
				<template v-slot:location="{ row }"> {{ row.location.name['tc'] }}</template>
				<template v-slot:status="{ row }">
					<n-badge :color="adjustmentStatusName(row.status).color"> {{ adjustmentStatusName(row.status).label }}</n-badge>
				</template>
			</n-data-table>
		</div>
		<div class="lg:col-span-1 space-y-2 text-xl h-full overflow-auto">
			<p class="text-4xl pb-2">{{ adjustmentOrder.id ? '修改' : '新增' }}調整</p>
			<div class="flex space-x-2">
				<label for="merchant">所屬商戶</label>
				<n-select v-model="adjustmentOrder.merchant" :options="merchants" @input="adjustmentOrder.merchantId = adjustmentOrder.merchant.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇商戶"></n-select>
			</div>

			<div v-if="adjustmentOrder.merchant.id" class="flex space-x-2">
				<label for="name">位置類別</label>
				<n-select v-model="adjustmentOrder.location" :options="locationByMerchantId(adjustmentOrder.merchant.id)" @input="adjustmentOrder.locationId = adjustmentOrder.location" :reduce="option => option.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇類別"></n-select>
			</div>
			<div v-if="adjustmentOrder.items.length > 0" class="space-y-2">
				<p class="text-xl">商品調整列表</p>
				<div v-for="(item, index) in adjustmentOrder.items" :key="(item, index)">
					<div class="flex space-x-2">
						<label for="product">商品</label>
						<n-select v-model="item.product" :options="catalogByMerchantId(adjustmentOrder.merchant.id, 'PRODUCT')" @input="item.productId = item.product.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇商品"></n-select>
					</div>
					<div class="flex space-x-2">
						<label for="variant">變化</label>
						<n-select v-model="item.variant" :options="catalogByMerchantId(adjustmentOrder.merchant.id, 'VARIANT')" @input="item.variantId = item.variant.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇類別"></n-select>
					</div>
					<div class="flex space-x-2">
						<label for="number">數量</label>
						<input type="number" v-model.number="item.quantity" class="w-full h-full text-base border border-gray px-3" placeholder="請選擇數量" />
					</div>
				</div>
			</div>

			<div v-if="adjustmentOrder.merchant.id" class="space-y-2">
				<p class="text-xl">加入新商品</p>
				<div class="flex space-x-2">
					<label for="product">商品</label>
					<n-select
						v-model="adjustmentItem.product"
						:options="catalogByMerchantId(adjustmentOrder.merchant.id, 'PRODUCT')"
						@input="
							adjustmentItem.productId = adjustmentItem.product.id
							adjustmentItem.variant = ''
						"
						:get-option-label="option => option.name['tc']"
						class="w-full h-full text-base"
						placeholder="請選擇商品"
					></n-select>
				</div>
				<div class="flex space-x-2">
					<label for="variant">變化</label>
					<n-select v-model="adjustmentItem.variant" :options="catalogByMerchantId(adjustmentOrder.merchant.id, 'VARIANT')" @input="adjustmentItem.variantId = adjustmentItem.variant.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇類別"></n-select>
				</div>

				<div class="flex space-x-2">
					<label for="number">數量</label>
					<input type="number" v-model.number="adjustmentItem.quantity" class="w-full h-full text-base border border-gray px-3" placeholder="請選擇類別" />
				</div>

				<n-button @onClick="addAdjustmentItem(adjustmentItem)">加入新商品</n-button>

				<div class="flex space-x-2">
					<label for="status">調整狀態</label>
					<n-select v-model="adjustmentOrder.status" :options="adjustmentStatusOptions" :reduce="status => status.value" class="w-full h-full text-base" placeholder="請選擇狀態"></n-select>
				</div>

				<p v-if="error" class="text-danger">{{ '錯誤訊息:' + error }}</p>

				<div class="flex space-x-2">
					<n-button v-if="adjustmentOrder.id" @onClick="$router.go(0)" color="danger">返回</n-button>
					<n-button @onClick="adjustmentOrder.id ? updateAdjustmentOrder(adjustmentOrder) : createAdjustmentOrder(adjustmentOrder)">{{ adjustmentOrder.id ? '修改調整' : '加入調整' }}</n-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { adjustmentOrder, currentAdjustmentItem } from '@/model/adjustmentOrder'

export default {
	name: 'Home',
	data() {
		return {
			adjustmentItem: currentAdjustmentItem(),
			adjustmentOrder: adjustmentOrder(),
			fields: [
				{
					label: '編號',
					prop: 'referenceId',
					sortable: true,
				},
				{
					label: '所屬商戶',
					prop: 'merchant',
					sortable: true,
				},
				{
					label: '庫存位置',
					prop: 'location',
					sortable: true,
				},
				{
					label: '狀態',
					prop: 'status',
					sortable: true,
				},
			],
			error: '',
		}
	},
	computed: {
		...mapState(['statusOptions', 'adjustmentStatusOptions']),
		...mapGetters([
			// merchant
			'merchants',
			'merchantName',
			'currentMerchant',
			// location
			'locations',
			'locationByMerchantId',
			'locationName',
			// catalog
			'catalog',
			'catalogMerchant',
			'catalogItem',
			'catalogByMerchantId',
			// inventory
			'inventories',
			'currentInventory',
			// adjustmentOrder
			'adjustmentOrders',
			'currentAdjustmentOrder',
			// transferOrder
			// state
			'statusName',
			'adjustmentStatusName',
		]),
	},
	methods: {
		async addAdjustmentItem(adjustmentItem) {
			if (!adjustmentItem.product) return
			if (!adjustmentItem.variant) return
			await this.adjustmentOrder.items.push(adjustmentItem)
			this.adjustmentItem = await currentAdjustmentItem()
		},

		async createAdjustmentOrder(data) {
			try {
				data.merchantId = data.merchant.id
				data.locationId = data.location
				data.items.forEach(item => ((item.productId = item.product.id), (item.variantId = item.variant.id)))
				await this.$store.dispatch('createAdjustmentOrder', { adjustmentOrder: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async getAdjustmentOrder(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('getAdjustmentOrder', { id })
				this.adjustmentOrder = this.currentAdjustmentOrder
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async archiveAdjustmentOrder(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('archiveAdjustmentOrder', { id })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async updateAdjustmentOrder(data) {
			try {
				data.merchantId = data.merchant.id
				data.locationId = data.location.id
				data.items.forEach(item => ((item.productId = item.product.id), (item.variantId = item.variant.id)))
				this.error = ''
				await this.$store.dispatch('updateAdjustmentOrder', { id: data.id, adjustmentOrder: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
	},
}
</script>
